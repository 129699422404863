<template>
    <div>
    <rating></rating>
    </div>
</template>

<script>
import rating from "../components/Rating.vue";

export default {
    components:{
        rating
    },
}
</script>

<style>

</style>